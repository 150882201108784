.breadcrumb-nav {
  display: flex;
  align-items: center;
  flex: 1;
}

.breadcrumb {
  display: flex !important;
  list-style: none;
  flex: 1 !important;
  padding: 0;
  margin: 0;
}

.breadcrumb li {
  margin-right: 10px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #E1242A;
}

.breadcrumb li a:hover {
  text-decoration: underline;
}

.breadcrumb li::after {
  content: '>';
  margin-left: 10px;
}

.breadcrumb li:last-child::after {
  content: '';
  margin-left: 0;
}

.breadcrumb li:last-child a {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
