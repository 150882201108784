@media (min-width: 1620px) {
    .lg-screen-col-2 {
        grid-column: span 2 / span 2;
    }

    .lg-screen-col-3 {
        grid-column: span 3 / span 3;
    }

    .lg-screen-col-7 {
        grid-column: span 7 / span 7;
    }
}

.max-w-25p {
    max-width: 25%;
}
