$white: #fff;
$dark: #000;
$dark-2: #333;
$dark-3: #191919;

// primary color
$theme-color: #E1242A;
$theme-color-dark: #a40a0f;
$alter-color: #f3b447;


$text-color: #444444;
$text-alter : #3c3c3c;
$text-light: #999999;
$text-light-2: #707070;
$text-light-3:#cacaca;
$light: #bcbcbc;
$ip-bg: #ededed;
$border-clr: #c6c6c6;

$placeholder-color: #aaaaaa;

$active-color: #5db75d;
$danger-color: #EF3F35;


$failed-bg:rgba(255, 0, 0, 0.150);

// fonts
